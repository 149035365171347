.btt-list-row-page{
  
  h3{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top:2rem;
    padding-bottom:0rem;

  }

.btt-training-info-card {
  position: absolute;
  top: 20.375rem;
  left: 1.25rem;
  right: 1.25rem;
  z-index: 2;
  margin: 0px auto;
  border: 0.5px solid rgba(255,255,255,0.1);
  min-height: 8.75rem;

  background: linear-gradient(72.13deg, rgba(19, 19, 19, 0.6) 40.76%, rgba(19, 19, 19, 0.42) 79.32%, rgba(42, 42, 42, 0) 100%);
  
  border-radius: 6px;
  box-sizing: border-box;
  padding: 1rem;

  &--title {
    font-size: 1.5rem;
    font-weight: normal;
    margin-top: 0;
  }
  &--subtitle {
    color: var(--btt-color-gray);
    font-size: 0.75rem;
  }
  &--footer {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: space-between;

    btt-content-creator{
      ion-img{
        width: 32px !important;
        height: 32px !important;
      }
    }

    ion-button {
      --height: 2rem;
      --width: 5.4375rem;
      --background:  var(--btt-color-auxiliary);
      --border-style: 3px;
    }
  }
}

.div__btt_data_training_details{
  height:4rem;
  margin-top: 5.75rem;
  margin-left: 1.25rem;
  margin-right:1.25rem;
  display: flex;
  
  .div__btt_detail_training{
    display: flex;
    flex-direction: column;
    flex:1;
    align-items:center;
    justify-content:center;

    & + div{
      border-left: 1px solid var(--btt-color-gray-01);
    }


    span{
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      // line-height: 26px;
      letter-spacing: 0em;
      text-align: center;
      margin: 0;
      padding: 0;
    }
    p{
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      // line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
      margin: 0;
      padding: 0;
      color: #B7B7B7;
    }
  }
}
.btt__padding{
  margin-top: 2.125rem;
  margin-left: 1.25rem; 
  margin-right: 1.25rem; 
}

.div__btt_list_video_data{
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-bottom: 1.9375rem !important;
  // height: 6.0625rem;
  display: grid;
  grid-template-columns: 7.25rem 1fr 0.75rem;
  gap: 1rem;
  border-bottom:1px solid #202020;

  .div__btt_content_video_image{
    position: relative;
    img{
      height: 6.0625rem;
      width: 7.25rem;
      left: 0rem;
      top: 0rem;
      border-radius: 0.375rem;
      object-fit: cover;
      object-position: center;
    }
  }
  .div__btt_content_video_image:before{
    content:'';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 6.0625rem;
    width: 7.25rem;
    border-radius: 0.375rem;
    background: linear-gradient(180deg, rgba(19, 19, 19, 0) 51.11%, #131313 100%);
    z-index: 1;
  }

  .div__btt_content_video_info{
    display:flex;
    flex-direction: column;
    align-items:flex-start;
    padding: 1rem 0rem;
    height: 6.0625rem;
    gap: 0.5rem;

    h5{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 115%;
      margin: 0rem;
      padding: 0rem;
    }
    p{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 150%;
      margin: 0rem;
      padding: 0rem;
      color: #B7B7B7;
      
    }
  }
  .btt-item-training-scheduled--content-detail-push{
   margin: auto;
   color: var(--btt-color-gray-01);
  }
  
}
.btt_border_bottom{
  border-bottom:1px solid #202020;
}
}