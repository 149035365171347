.modal-lg {
  --min-height: 80vh;
  --min-width: 80vw;
}

.modal-full {
  --min-height: 100vh;
  --min-width: 100vw;
}

.modal-camera {
  background-color: transparent;
  --background: transparent;
}

.modal-rate {
  --min-height: 100vh;
  --min-width: 100vw;
  --background: transparent !important;
  opacity: 1;
}

.modal-half-web {
  overflow: auto;
  --max-width: 500px;
  --min-height: 440px;
  --height: auto;
  --max-height: 500px;
  // --height: 300px;
  .ion-page {
    --height: auto;
    overflow: auto;
  }
}

.modal-half {
  align-items: flex-end;
  // background: var(--btt-modal-background);
  // --height: 50%;
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
    }
  }
  // &.keyboard-opened {
  //   --height: calc(100% - 56px);
  // }

  .modal-wrapper {
    // --background: var(--btt-color-dark1);
    border-radius: 12px 12px 0 0;

    &::before {
      // background: var(--btt-color-dark1);
      content: "";
      height: calc(16px + 4px + 16px);
      left: 0;
      right: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
    &::after {
      // background: var(--ion-color-medium);
      border-radius: 50px;
      content: "";
      height: 4px;
      left: calc(50% - 20px);
      position: absolute;
      top: 16px;
      width: 40px;
      z-index: 2;
    }
  }
}

.modal-error {
  .modal-wrapper {
    --background: var(--btt-modal-error-background);
  }
}
.modal-success {
  .modal-wrapper {
    --background: var(--btt-modal-success-background);
  }
}

.modal-white {
  --background: var(--btt-modal-white-color);
  .modal-handle {
    background: var(--btt-color-auxiliar) !important;
  }
}

.modal-white-web {
  --background: var(--btt-modal-white-color);
  .modal-handle {
    background: var(--btt-color-auxiliar) !important;
  }

  overflow: auto !important;
  width: 100% !important;
  --max-width: 700px !important;
  --min-height: 440px !important;
  --height: auto !important;
  --max-height: 500px !important;
  // --height: 300px;
  .ion-page {
    --height: auto !important;
    overflow: auto !important;
  }
}

.modal-success-pass {
  --background: var(--btt-color-dark1);
}

.modal-dark {
  --background: var(--btt-color-gray-darker);
  .modal-handle {
    background: var(--btt-color-gray-darker) !important;
  }
}

.modal-white-dark {
  --background: var(--btt-color-gray-darker);
  .modal-handle {
    background: var(--btt-color-auxiliar) !important;
  }

  overflow: auto !important;
  width: 100% !important;
  --max-width: 700px !important;
  --min-height: 440px !important;
  --height: auto !important;
  --max-height: 500px !important;
  // --height: 300px;
  .ion-page {
    --height: auto !important;
    overflow: auto !important;
  }
}
