// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  // new btt colors
  --btt-color-primary: #ffcd00;
  --btt-color-primary-rgb: 255, 205, 0;
  --btt-color-auxiliary: #ffb600;
  --btt-color-auxiliary-rgb: 255, 182, 0;

  --btt-color-yellow-card-workout: #ffcd50;
  --btt-color-dark-gray-workout: #1e1e1e;
  --btt-color-dark-gray-check-btn: #1a1b1b;

  --btt-color-gray-01: #535353;
  --btt-color-gray-02: #202020;
  --btt-color-gray-03: #5f5c6b;
  --btt-color-gray-04: #7a7786;
  --btt-color-gray-05: #8c8a97;
  --btt-color-gray-06: #aaaaaa;
  --btt-color-gray-07: #1a1a1b;

  --btt-color-white: #ffffff;

  --btt-font-family-primary: "Poppins", sans-serif;
  --btt-font-family-modal-primary: "Inter", sans-serif;

  --btt-modal-white-color: #f4f4f4;

  --btt-back-btn-text-color: #121212;
  --btt-new-background-color: #141414;
  --btt-share-page-black-color: #414141;

  --btt-block-content-background: 21, 21, 21;
  --btt-card-content-overlayed: 42, 42, 42;

  --btt-border-animation-color: #2b2311;

  --btt-progress-bar-bg: #afafaf;

  --btt-share-image-text-color: #e5e5e5;
  --btt-share-image-gradient-text: 20, 20, 20;
  --btt-share-image-border-color: #242425;
  --btt-share-image-gradient-bg-dark: 94, 84, 60;
  --btt-share-image-gradient-bg-primary: 255, 183, 0;
  --btt-share-jump-gray-color: #787878;
  --btt-white-background-rgb: 242, 242, 242;

  --btt-background-info-alert-rgb: 255, 183, 0;

  --btt-dark-feed-color: #181818;

  // finish new btt colors

  --ion-color-primary: #ffcd00;
  --ion-color-primary-rgb: 255, 205, 0;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e0b400;
  --ion-color-primary-tint: #ffd21a;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #10c141;
  --ion-color-success-rgb: 16, 193, 65;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffb600;
  --ion-color-warning-rgb: 255, 182, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #ffb400;
  --ion-color-warning-tint: #ffb000;

  /** danger **/
  --ion-color-danger: #c11010;
  --ion-color-danger-rgb: 193, 16, 16;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: #000000;

  /** medium **/
  --ion-color-medium: #535353;
  --ion-color-medium-rgb: 83, 83, 83, 1;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #555;
  --ion-color-medium-tint: #575757;

  /** medium dark **/
  --ion-color-medium-dark: #0e0e0e;
  --ion-color-medium-dark-rgb: 14, 14, 14, 1;
  --ion-color-medium-dark-contrast: #ffffff;
  --ion-color-medium-dark-contrast-rgb: 255, 255, 255;
  --ion-color-medium-dark-shade: #010101;
  --ion-color-medium-dark-tint: #151515;

  /** light **/
  // --ion-color-light: #f4f5f8;
  --ion-color-light: #ffffff;
  // --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  // facebook
  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59, 89, 152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;

  // sticky-notes-hours
  --ion-color-sticky-notes-hours: #ffffff;
  --ion-color-sticky-notes-hours-rgb: 255, 255, 255;
  --ion-color-sticky-notes-hours-contrast: #535353;
  --ion-color-sticky-notes-hours-contrast-rgb: 83, 83, 83;
  --ion-color-sticky-notes-hours-shade: #fcfcfc;
  --ion-color-sticky-notes-hours-tint: #f0f0f0;

  // Replace IONIC DEFAULT VARIABLES
  // Bittrainers Standard: Dark & Yellow
  --ion-background-color: #141414; // var(--btt-color-dark1);
  --ion-background-color-rgb: 20, 20, 20;
  --ion-text-color: #fff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  // custom modal colors
  --btt-color-custom-modal-1: #ffb700;
  --btt-color-custom-modal-2: #ffd734;

  // start toolbar style
  // --ion-toolbar-background: var(--ion-color-dark); //old background toolbar style
  --ion-toolbar-background: #141414;
  .btt-toolbar {
    padding-top: 30px;
  }
  .btt-toolbar-close-icon {
    font-size: 30px;
    // height: auto;
  }

  .btt-toolbar-arrow-icon {
    font-size: 26px;
  }

  .reorder-safe {
    pointer-events: none;
  }

  ion-reorder-group[disabled] .reorder-list {
    pointer-events: auto;
  }

  // finish toolbar style

  // --ion-item-background: rgba(0, 0, 0, 0.3);
  --ion-item-background: transparent;

  --ion-card-background: #1c1c1d;

  --ion-font-family: "Poppins", "Roboto";

  // Custom Colors
  --btt-header-background: #0e0e0e;

  --ion-tab-bar-background: var(--btt-header-background);

  --btt-color-gray: #b7b7b7;
  --btt-color-gray-darkiest: #0a0a0a; // --ion-toolbar-background // text color impacted
  --btt-color-gray-darker: #202020;
  --btt-color-gray-timer: #fefcff;
  --btt-color-gray-timer-border: #cdcbd0;
  --btt-color-gray-password-dark: #2b3340;

  --btt-semitransparent-background: rgba(var(--ion-color-dark-rgb), 0.7);

  --btt-color-title: var(--ion-color-light);
  --btt-color-subtitle: var(--btt-color-gray-06);
  --btt-color-input-border: var(--ion-color-medium);

  --btt-color-dark1: #131313;
  --btt-modal-background: rgba(10, 10, 10, 0.7);
  --btt-black-check-mark: #141414;

  --btt-modal-error-background: linear-gradient(
    180deg,
    #c21010 0%,
    #950909 74.69%
  );
  --btt-modal-success-background: linear-gradient(
    180deg,
    #10c242 0%,
    #04962d 74.69%
  );
  --btt-color-error: 246, 79, 119;
  --btt-color-error-border: #de3961;
  --btt-color-error-icon: #f64f77;
  --btt-color-error-custom: #ff7d7d;

  // border
  --btt-border-default: 1px solid var(--btt-color-gray-darker);
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

button.alert-button.alert-button-cancel {
  color: var(--btt-color-white);
}
.ion-color-sticky-notes-hours {
  --ion-color-base: var(--ion-color-sticky-notes-hours);
  --ion-color-base-rgb: var(--ion-color-sticky-notes-hours-rgb);
  --ion-color-contrast: var(--ion-color-sticky-notes-hours-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sticky-notes-hours-contrast-rgb);
  --ion-color-shade: var(--ion-color-sticky-notes-hours-shade);
  --ion-color-tint: var(--ion-color-sticky-notes-hours-tint);
}
