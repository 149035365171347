ion-button {
  --border-radius: 6px;
  --border-width: 1px;
  text-transform: none;

  font-weight: 600;

  &[underline] {
    text-decoration: underline;
  }
  &:not[size] {
    height: 2.875rem;
  }

  &[size="small"] {
    height: 2rem;
    font-size: 0.75rem;
    --padding-end: 1.25rem;
    --padding-start: 1.25rem;
  }
}

.button-sexo-treino {
  align-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  background: #000;
  color: rgba(var(--ion-color-light-rgb), 0.85);
  font-size: 1rem;
  margin-bottom: 0;
  text-align: center;
  // width: calc(100% - 2rem);
  height: calc(50% - 0.5px);
  width: 100%;

  .area-clicavel {
    background: rgba(#1b1c1e, 0.75);
    clip-path: polygon(0 0, 100% 1%, 100% 100%, 20% 100%, 0% 80%);
    cursor: pointer;
    padding: 2rem 0.75rem;
    width: 15rem;
  }

  img {
    height: 5rem;
    margin-bottom: 4px;
  }

  &:first-child {
    border-bottom: 1px solid rgba(#ddd, 0.2);
  }
}
.button-plus {
  background: var(--ion-color-warning);
  border-radius: 30px;
  color: var(--ion-color-warning-contrast);

  align-items: center;
  display: inline-flex;
  justify-content: center;

  text-align: center;

  height: 1.5rem;
  width: 2.5rem;
}
.btn-wide {
  font-size: 12px;
  height: 2rem;
  line-height: 12px;
}

ion-fab-list[side="top-right"] {
  top: auto;
  bottom: 0;
  right: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.auto-fab {
  --border-radius: 10px;
  width: 15rem !important;

  ion-icon {
    margin-right: 5px;
  }
}


