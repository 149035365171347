// calendar
// div para o ícone de halteres
.div__icon_barbell {
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  margin: auto;
  background-color: var(--ion-background-color) !important;
  color: #553d00 !important;
  border-radius: 50% !important;
  width: 1rem;
  height: 1rem;
  margin-top: -25%;
}

.monthview-current {
  background-color: transparent !important;
  color: var(--ion-color-warning) !important;
  border-radius: 50%;
  border: 1px solid #535353 !important;
}
// efeito de blur enquanto há transição de páginas do calendário e formatação de estilo
.calendar__blur {
  filter: blur(1px);
}
.content__calendar {
  // efeito  aplicado ao dia com evento em página secundária
  .monthview-secondary-with-event {
    opacity: 0.3;
    position: relative;
    color: #553d00 !important;
    background-color: transparent !important;
    .div__icon_barbell {
      display: flex;
    }
    z-index: 5;
  }
  // efeito  aplicado ao dia selecionado na página de mês atual
  .monthview-selected {
    font-weight: bold;
    background-color: transparent !important;
    color: var(--ion-color-warning) !important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .div__icon_barbell {
      background-color: var(--ion-background-color) !important;
      color: var(--ion-background-color) !important;
      display: none;
    }

    &::after {
      display: none;
    }
    &::before {
      color: var(--ion-color-warning) !important;
    }
  }

  .monthview-primary-with-event,
  .calendar-event-inner {
    position: relative;
    color: #553d00 !important;
    background-color: transparent !important;

    .div__icon_barbell {
      display: flex;
    }
  }
  .monthview-disabled {
    pointer-events: none !important;
    opacity: 0.3 !important;
  }
  th {
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
  td,
  th,
  table {
    border: 0 !important;
  }
}
.day__between-days {
  &::after {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(var(--ion-color-warning-rgb), 0.3) !important;
    max-height: 2rem !important;
    margin: auto;
  }
}
.day__start-date {
  &::after {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    background-color: rgba(var(--ion-color-warning-rgb), 0.3) !important;
    max-height: 2rem !important;
    margin: auto;
  }
}
.day__end-date {
  &::after {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
    background-color: rgba(var(--ion-color-warning-rgb), 0.3) !important;
    max-height: 2rem !important;
    margin: auto;
  }
}
.event-detail-container {
  display: none !important;
}

// circle bar
.c-circular-progress {
  border-radius: 50%;
  display: block;
  width: 2rem;
  height: 2rem;
  margin: auto !important;
  position: relative;
  align-items: center;

  &::before {
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    left: 0px;
    right: 1px;
    top: 0px;
    bottom: 0px;
    position: absolute;
    transform: scale(0.9);
    height: 100%;
    width: 100%;
  }

  @for $i from 0 through 100 {
    $deg: 90deg + (3.6 * $i);

    &--#{$i} {
      $direction: if($i < 51, right, left);
      $color: if($i < 51, #535353, var(--ion-color-warning));
      $bgColor: if(
        $i == 100,
        var(--ion-color-warning),
        var(--ion-background-color)
      );
      $fontWeight: if($i == 100, 800, 400);
      $showCheckIcon: if($i == 100, flex, none);

      .div__icon_barbell {
        display: #{$showCheckIcon} !important;
      }

      &::before {
        background-color: #{$bgColor};
        font-weight: #{$fontWeight};
      }

      background: linear-gradient(
          to #{$direction},
          #{$color} 50%,
          transparent 50%
        ),
        linear-gradient(#{$deg}, var(--ion-color-warning) 50%, #535353 50%);

      @for $j from 1 through 32 {
        &--#{$j} {
          &::before {
            content: quote('#{$j}');
          }
        }
      }
    }
  }
}
