.btt-fade-in {
  animation: bttFadeInAnimation 1s;
}

@keyframes bttFadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
