ion-item-divider {
  --background: transparent;
  --color: var(--ion-color-warning);
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
}

.btt-item-danger-clear {
  color: var(--ion-color-danger);
}
