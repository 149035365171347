.btt-slider {
  .swiper {
    --bullet-background: rgba(255, 255, 255, 0.6);
    --bullet-background-active: #fff;

    .swiper-pagination-bullet {
      border-radius: 5px;
      height: 5px;
      margin: 0 5px;
      width: 1.2rem;

      &-active {
        width: 2.2rem;
      }
    }
  }
}

.isWeb {
  .swiper {
    .swiper-pagination {
      bottom: 0px;
      padding: 2rem;
      background: linear-gradient(360deg, #131313 0%, rgba(19, 19, 19, 0) 86.99%);
    }
  }
}

.btt-slider-full {
  .swiper-pagination-bullet {
    border-radius: 5px;
    height: 5px;
    margin: 0 5px;
    width: 1.2rem;

    &-active {
      width: 2.2rem;
    }
  }
}
