.btt-input {
  border: 1px solid var(--btt-color-input-border);
  border-radius: 6px;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &.item-has-focus {
    border-color: var(--ion-color-light);
    ion-input {
    }
  }
}
ion-label + .btt-input {
  margin-top: 0.5rem;
}

.btt-input-custom {
  border: 1px solid var(--btt-share-jump-gray-color);
  background-color: var(--ion-color-medium-dark);
  border-radius: 0.375rem;

  --min-height: 2.625rem;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &.item-has-focus {
    border-color: var(--ion-color-light);
    ion-input {
    }
  }
}
ion-label + .btt-input-custom {
  margin-top: 0.5rem;
}
