.card-dark-transparent {
    background: rgba(#000, 0.25);
    border-radius: 8px;
    color: #aaa;
    text-align: center;

    ion-card-header {
        color: #aaa;
        font-size: 2rem;
        font-weight: bold;
    }

    .card-content,
    p {
        color: #aaa;
        font-size: 1.5rem;
    }
    a {
        color: #fff;
        font-size: 1.6rem;
    }
}
