ion-list {
    background: transparent !important;
    &.list-black {
        background: #000 !important;
    }
}
.lista-exercicio {
    ion-item {
        --background: rgba(var(--ion-color-dark-rgb), 0.75);
    }
    ion-label {
        --color: var(--ion-color-warning) !important;
    }
}
