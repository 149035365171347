ion-icon {
    &[class*="custom-"] {
        &:before {
            content: "";
            mask-size: contain;
            mask-position: 50% 50%;
            mask-repeat: no-repeat;
            background: currentColor;
            width: 1em;
            height: 1em;
        }
    }

    &[class*="custom-nutricao"] {
        &:before {
            mask-image: url("/assets/img/svg/nutricao.svg");
        }
    }

    &[class*="custom-perfil"] {
        &:before {
            mask-image: url("/assets/img/svg/perfil.svg");
        }
    }

    &[class*="custom-altere"] {
        &:before {
            mask-image: url("/assets/img/svg/altere.svg");
        }
    }

    &[class*="custom-treinos"] {
        &:before {
            mask-image: url("/assets/img/svg/treinos.svg");
        }
    }

    &[class*="custom-shopping"] {
        &:before {
            mask-image: url("/assets/img/svg/shopping.svg");
        }
    }

    &[class*="custom-academias"] {
        &:before {
            mask-image: url("/assets/img/svg/academias.svg");
        }
    }

    &[class*="custom-feminino"] {
        &:before {
            mask-image: url("/assets/img/svg/feminino.svg");
        }
    }

    &[class*="custom-masculino"] {
        &:before {
            mask-image: url("/assets/img/svg/masculino.svg");
        }
    }

    &[class*="custom-parceiros"] {
        &:before {
            mask-image: url("/assets/img/svg/parceiros.svg");
        }
    }

    &[src*="assets/svg/btt-play-icon.svg"] {
        font-size: 1.5rem;
    }
}
