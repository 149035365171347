.head-treino {
    border-bottom: 2px solid rgba(var(--ion-color-light-rgb), 0.1);
    border-top: 2px solid rgba(var(--ion-color-light-rgb), 0.1);
    display: flex;
    justify-content: space-between;
}

.block-yellow {
    background: var(--ion-color-primary);
    color: var(--ion-color-dark);
}

.block-transparent {
    background: transparent;
    color: var(--ion-color-light);
}

.block-yellow,
.block-transparent {
    align-items: center;
    font-size: 1.6rem;
    justify-content: center;
    text-transform: uppercase;

    .block-letra {
        display: block;
        font-size: 4rem;
    }

    .block-content {
        text-align: center;
        padding: 10px;
    }
}

.head-start {
    flex-basis: 33%;
    flex-grow: 1;
    margin-right: 10px;
}

.head-content {
    // align-items: center;
    display: flex;
    flex-basis: 66%;
    flex-direction: column;
    flex-grow: 2;
    justify-content: center;
    padding: 10px 0;

    h3 {
        color: var(--ion-color-primary);
        font-size: 1.6rem;
        margin: 0 0 10px;
    }

    h4 {
        color: var(--ion-color-warning);
        font-size: 1.4rem;
        margin: 0;
    }
}

// Treino descricao
.treino-descricao {
    font-size: 1.2rem;
    text-align: center;
}

// Lista de exercícios do treino
.exercicio-nome {
    color: var(--ion-color-warning);
    font-size: 1.25rem !important;
    line-height: 1.6rem !important;
}

.exercicio-info {
    a {
        color: var(--ion-color-light);
    }
}

.item-action-wrapper {
    align-items: center;
    display: flex;
}

.flex-wrapper {
    // display: flex;
    &:after {
        display: block;
        content: "";
        clear: both;
    }
}

.item-lista {
    ion-thumbnail {
        align-self: flex-start;
    }

    ion-toggle {
        float: right;
    }
}

.saiba-mais-link {
    align-items: center;
    display: flex;
    float: right;

    .btn-text {
        margin-right: 10px;
    }
}
