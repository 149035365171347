/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./theme/custom/color-helpers";
@import "./theme/custom/_cards";
@import "./theme/custom/_buttons";
@import "./theme/custom/_flex";

// calendar
@import "./theme/custom/calendar";

// Swiper
@import "~swiper/scss";
@import "~swiper/scss/autoplay";
@import "~swiper/scss/pagination";
@import "~@ionic/angular/css/ionic-swiper";
@import "./theme/custom/swiper.scss";

// Custom icons
@import "./theme/custom/icon";

// Overrides
@import "./theme/custom/ion-list";
@import "./theme/custom/ion-item";
@import "./theme/custom/input";
@import "./theme/custom/ion-select";

// External Libs
@import "./theme/custom/pagination";

// Images
@import "./theme/custom/images";

// Training
@import "./theme/custom/training";

// List page style after cards
@import "./theme/custom/btt-list-row-page";
// Loading
@import "./theme/custom/loading";

// Modals
@import "./theme/custom/modals.scss";

@import "~cropperjs/dist/cropper.min.css";

// animations
@import "./theme/custom/animations.scss";

/******* FONTS ********/
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("assets/fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("assets/fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "Poppins-Light";
  src: url("assets/fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: "Poppins-Black";
  src: url("assets/fonts/Poppins-Black.ttf");
}
@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-Regular.ttf");
}
@font-face {
  font-family: "Inter-Bold";
  src: url("assets/fonts/Inter-Bold.ttf");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url("assets/fonts/Inter-SemiBold.ttf");
}
@font-face {
  font-family: "Inter-Light";
  src: url("assets/fonts/Inter-Light.ttf");
}
@font-face {
  font-family: "Inter-Black";
  src: url("assets/fonts/Inter-Black.ttf");
}
/******* FONTS ********/

.ion-page:not(ion-app) {
  // background: url("assets/img/background-page-darker-min.jpg") top center/cover
  //   no-repeat !important;
}

/** Page Defaults **/
.default-padding {
  --ion-padding: 20px;
}
.btt-page {
  &--title {
    color: var(--btt-color-title);
    font-size: 2.25rem;
    font-weight: bold;
    margin-top: 0;
  }
  &--subtitle {
    color: var(--btt-color-subtitle);
    font-size: 1rem;
  }

  &--title-secondary {
    color: var(--btt-color-title);
    font-size: 1.125rem;
    font-family: "Poppins-SemiBold";

    align-items: center;
    display: flex;
    justify-content: space-between;

    ion-button {
      font-size: 0.875rem;
    }
  }

  &--section {
    padding-left: 20px;
    padding-right: 20px;
  }

  &--header-card {
    background: var(--btt-color-dark1);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    &.header-end-floating {
      margin-bottom: 80px;
    }

    &.ion-padding {
      --ion-padding: 20px;
    }
  }

  &--footer {
    color: var(--btt-color-subtitle);
    font-size: 0.875rem;

    &--button {
      height: 1.5rem;
    }
  }

  &.ion-padding {
    --ion-padding: 32px;
  }
}

.btt-divider {
  background-color: var(--btt-color-gray-darker);
}

.btt-footer {
  &.ion-padding {
    --ion-padding: 30px;
  }
}

ion-content {
  --background: transparent;
}

ion-footer {
  padding-top: 0rem !important;

  ion-toolbar {
    --background: transparent;
  }
}

.btt-searchbar {
  --background: var(--ion-color-medium-dark);
  --border-radius: 6px;
  padding: 0;

  .searchbar-input {
    border: 1px solid var(--btt-color-input-border);
    font-size: 0.875rem;
    height: 48px;

    padding-inline-start: 16px;
  }

  &.searchbar-has-focus {
    .searchbar-input {
      border-color: var(--ion-color-light);
    }
  }
  .searchbar-search-icon {
    left: unset;
    right: 16px;
    top: 14px;
  }
  .searchbar-clear-button {
    right: calc(25px + 13px);
  }
}

.pointer {
  cursor: pointer;
}

.errors {
  color: var(--ion-color-danger);
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  margin-bottom: 1rem;
}

// Adicionando estilo do Ion Input
.bt-ion-input {
  border-radius: var(--border-radius);
  padding-left: unset;
  padding-right: unset;
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-decoration: inherit;
  text-indent: inherit;
  text-overflow: inherit;
  text-transform: inherit;
  text-align: inherit;
  white-space: inherit;
  color: inherit;
  display: inline-block;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  border: 0;
  outline: none;
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

// Image cards cut
// decorative
.img-clip-right {
  -webkit-clip-path: polygon(100% 0, 100% 65%, 65% 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% 65%, 65% 100%, 0 100%, 0 0);
}
.img-clip-full {
  clip-path: polygon(10% 0, 100% 0, 100% 75%, 90% 100%, 0 100%, 0 25%);
}
.img-full {
  height: auto;
  width: 100%;
}

// Titles and texts
.title-top {
  border-bottom: 1px solid var(--ion-color-primary);
  color: var(--ion-color-light);
  margin: 10px 16px;
  padding-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
}

.blocked-resource {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: rgba(#000, 0.6);
  }
}

.blocked-icon {
  position: absolute;
  top: 5px;
  right: 5px;

  z-index: 1;
}

.premium-icon {
  position: absolute;
  top: 5px;
  left: 5px;

  z-index: 1;
}

.video-thumb--play {
  position: relative;

  .thumb-icon-play {
    align-items: center;
    display: flex;
    justify-content: center;

    background: rgba(var(--ion-color-dark-rgb), 0.75);
    border: 2px solid var(--ion-color-light);
    border-radius: 50%;
    opacity: 0.75;

    font-size: 0.8rem;
    text-align: center;

    left: calc(50% - 1rem);
    top: calc(50% - 1rem);
    position: absolute;
    z-index: 1;

    height: 2rem;
    width: 2rem;
  }
}

/* PrincipalPage */
.principal-card-background {
  ion-card {
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    // min-height: 110px;
    // padding-top: 100%;
    position: relative;
    text-align: center;

    width: 100%;
  }

  ion-slide {
    img {
      width: 100%;
    }
  }

  ion-card img {
    background-color: transparent;
    display: block;
    float: left;
    height: auto;
    left: 0;
    max-width: 100%;
    min-width: 100%;
    // max-width:100%;
    // max-height:100%;
    // position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  .card-title {
    color: #fff;
    display: flex;
    font-size: 1.5em;
    font-weight: bold;
    left: 5%;
    position: absolute;
    right: 15%;
    text-align: left;
    text-transform: uppercase;
    top: 25px;
    transform: translate(0, -10%);
    width: calc(100% - 45px);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.9);
  }

  .card-subtitle {
    font-size: 1em;
    position: absolute;
    top: 52%;
    width: 100%;
    color: #fff;
  }

  .overlay:before {
  }
}

.infinity-card {
  margin: 10px 0 !important;
  width: 100% !important;

  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  margin: 10px 15px;
  position: relative;
  text-align: center;

  &:not(.title-top-left) .card-title {
    align-items: center;
    display: flex;
    justify-content: center;

    height: 100%;
    width: 100%;

    padding: 15px;

    bottom: 0;
    left: 0;
    right: 0;
    top: 0;

    transform: none;
    text-align: center;
  }

  .card-title {
    color: #fff;
    display: flex;
    font-size: 1.5em;
    font-weight: bold;
    left: 15px;
    position: absolute;
    right: 15px;
    text-align: left;
    text-transform: uppercase;
    top: 25px;
    transform: translate(0, -10%);
    width: calc(100% - 45px);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.9);
  }

  .card-subtitle {
    font-size: 1em;
    position: absolute;
    top: 52%;
    width: 100%;
    color: #fff;
  }

  img {
    background-color: transparent;
    display: block;
    // float: left;
    height: auto;
    left: 0;
    max-width: 100%;
    min-width: 100%;
    // max-width:100%;
    // max-height:100%;
    // position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.custom-scrollbar {
  ::-webkit-scrollbar {
    display: block;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(var(--ion-color-primary-rgb), 0.5);
    border-radius: 2px;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-corner {
    display: none;
  }
}

// Customizações de páginas /*
.header-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  display: inline-flex;
  height: 50vw;
  position: relative;
  width: 100vw;

  align-items: center;
  justify-content: center;
  text-align: center;
}

.header-title {
  font-size: 2.8rem;
  font-weight: bold;
  text-shadow: -2px 2px 2px #000000;
  text-transform: uppercase;
}

.body-title {
  color: var(--btt-color-title);
  font-size: 2.25rem;
  font-weight: bold;
  margin-top: 0;
}

.body-subtitle {
  color: var(--btt-color-subtitle);
  font-size: 1rem;
}

.body-subtitle-premium {
  color: var(--ion-color-primary-shade);
}

// Partners
.partner-image {
  border-radius: 10px;
}
.partner-title {
  color: var(--ion-color-light);
  font-size: 1.75rem !important;
  font-weight: bold;
  margin: 15px 0;
}
.partner-description {
  color: var(--ion-color-light);
}

// Header navigate
.header-navigate {
  --padding-top: 44px;
  --padding-bottom: 6px;
}
// content
.btt-padding-content {
  --padding-bottom: 20px;
  --padding-top: 20px;
  --padding-end: 20px;
  --padding-start: 20px;
}
.div__covering_page_out {
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.btt-tt-body18 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */
  color: #ffffff;
  margin: 0;
  padding: 0;
}
// toolbar
// buttons
.btt-ion-button-auxiliar-color {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 150%;

  --background: var(--btt-color-auxiliary);
  opacity: 1;
  border-radius: 0.375rem;
  min-height: 3rem;
  margin-top: 3rem;
}
.btt-ion-button-auxiliar-color:disabled,
ion-button[disabled] {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 150%;

  --background: var(--btt-color-auxiliary);
  opacity: 0.4;
  border-radius: 0.375rem;
  min-height: 3rem;
  margin-top: 3rem;
}
.btt-ion-button-auxiliar-color-secondary {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 150%;
  background-color: transparent !important;
  --background: transparent !important;

  color: var(--btt-color-auxiliary);
  border: 1px solid var(--btt-color-auxiliary);
  opacity: 1;
  border-radius: 0.375rem;
  min-height: 3rem;
  // margin-top: 3rem;
}

// div list category
.div__content_select_card_content {
  ion-grid {
    margin: 0rem;
    padding: 0rem;
    margin-top: 30px;
  }
  ion-row {
    margin: 0rem;
    padding: 0rem;
  }

  .div__section_menu {
    padding-bottom: 0.8125rem;
  }
}

.swiper-pagination-bullet-active {
  background: #fff !important;
}

.swiper-pagination-bullet {
  background-color: #535353;
  // bottom: 10px !important;
}

.my_custom_popover {
  --min-width: 25.875rem;
}
.my_custom_popover_user_option {
  --min-width: 13.8125rem;
}

// Estilização dos cabeçalhos de páginas web
.div__header_page_out {
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;

  .div__header_page {
    max-width: 1216px;
    margin-left: auto;
    margin-right: auto;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ion-button {
      width: 2rem;
      height: 2rem;
      background-color: transparent;
      --padding-start: 0px;
      --padding-end: 0px;
      --padding-top: 0px;
      --padding-bottom: 0px;
      --box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);

      --background: linear-gradient(
        143.39deg,
        rgba(33, 33, 34, 0.135) 15.54%,
        rgba(51, 51, 52, 0.26) 67.06%
      );
      color: var(--btt-white-color);
      // opacity: 0.5;
      --border-radius: 6px;
    }
  }

  .btt__modal_web_style_custom {
    --min-width: 100vw;
    --min-height: 100vh;
  }
}

.btt__modal_web_style_custom {
  --min-width: 100vw;
  --min-height: 100vh;
}

.toast__alert_content {
  --background: var(--btt-color-white);
  --color: var(--ion-color-medium-dark);
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.5rem;
  opacity: 0.9;
}

.toast__alert_content_user {
  --background: var(--btt-share-image-text-color);
  --color: var(--ion-color-dark);
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 130%;
  opacity: 0.9;
}

.toast__alert_content_user_button {
  --color: var(--ion-color-dark);
  --background: red;
  font-weight: 400;
  font-size: 0.75rem !important;
  line-height: 130%;
  opacity: 0.9;
}

.cropper-view-box {
  outline-width: 0px !important;
}
.cropper-container {
  width: 100% !important;
  aspect-ratio: 8/10 !important;
}
.btt-horizontal-padding {
  --padding-start: 1.25rem;
  --padding-end: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.modal-handle {
  background-color: var(--btt-color-gray) !important;
  margin-top: 1rem;
}
