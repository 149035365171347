.text-primary {
    color: var(--ion-color-primary);
}
.text-warning {
    color: var(--ion-color-warning);
}
hr {
    border-color: var(--ion-color-primary);
    background-color: var(--ion-color-primary);
}

.modal-transparent {
    --background: rgba(0, 0, 0, 0.95);
    .ion-page {
        background: transparent !important;
    }
}

.block-transparent {
    background: transparent;
}
