.d-flex {
    display: flex;
}

.vertical-align-center {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;

    &:not(.no-wrap) {
        flex-wrap: wrap;
    }

    &.bg-black {
        background: #000;
    }
}
.vertical-align-end {
    // align-content: center;
    align-items: flex-end;
    display: flex;
    justify-content: center;
    height: 100%;

    &:not(.no-wrap) {
        flex-wrap: wrap;
    }

    &.bg-black {
        background: #000;
    }
}
.align-self-end {
    align-self: flex-end;
}

.item-content-flex {
    display: flex;
    justify-content: space-between;

    .item-content-end {
        flex-basis: 66%;
        flex-grow: 2;
    }
}
.justify-center {
    justify-content: center;
}

.head-start {
    flex-basis: 33%;
    flex-grow: 1;
    margin-right: 10px;
}
.block-letra {
    display: block;
    font-size: 6rem;
}
.block-content {
    text-align: center;
    padding: 10px;
}
