/** ANGULAR PAGINATION **/
.ngx-pagination {
    padding-left: 0;
    text-align: center;

    li {
        padding: 5px;
        text-align: center;
    }

    a,
    button {
        color: var(--ion-color-light) !important;
        &:hover {
            background: rgba(var(--ion-color-warning-rgb), 0.2) !important;
        }
    }
    .current {
        background: var(--ion-color-warning) !important;
        border-radius: 8px;
        padding: 10px !important;

        height: 40px;
        width: 40px;
    }
}
